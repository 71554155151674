<template>
  <b-form-group
    label="Birim Tipi"
    label-for="id_units"
  >
    <v-select
      id="id_units"
      v-model="itemData.id_units"
      label="title"
      placeholder="Seçiniz"
      :reduce="item => item.id"
      :options="units"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Units',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
    units() {
      return this.$store.getters['units/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('units/getDataList')
    },
  },
}
</script>
