<template>
  <div class="text-center text-primary">
    <template v-if="isAlert">
      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body py-1">
          <b-spinner />
          <div class="mt-1">
            Lütfen Bekleyiniz...
          </div>
        </div>
      </b-alert>
    </template>
    <template v-else>
      <b-spinner />
      <div class="mt-1">
        Lütfen Bekleyiniz...
      </div>
    </template>
  </div>
</template>

<script>
import { BSpinner, BAlert } from 'bootstrap-vue'

export default {
  name: 'PreLoading',
  components: {
    BSpinner,
    BAlert,
  },
  props: {
    isAlert: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
