<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <product-group />
        </b-col>
        <b-col cols="12">
          <product-title />
        </b-col>
        <b-col cols="12">
          <product-code />
        </b-col>
        <b-col cols="12">
          <product-content />
        </b-col>
        <b-col cols="12">
          <units />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <photo-gallery />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import ProductGroup from '@/views/Admin/Products/elements/ProductGroup.vue'
import ProductTitle from '@/views/Admin/Products/elements/ProductTitle.vue'
import ProductCode from '@/views/Admin/Products/elements/ProductCode.vue'
import Units from '@/views/Admin/Products/elements/Units.vue'
import ProductContent from '@/views/Admin/Products/elements/Content.vue'
import PhotoGallery from '@/views/Admin/Products/elements/PhotoGallery.vue'

export default {
  name: 'ProductCategoryForm',
  components: {
    ProductGroup,
    ProductTitle,
    ProductCode,
    ProductContent,
    PhotoGallery,
    Units,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
