<template>
  <b-modal
    v-model="uploadModal.status"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="lg"
    title="Toplu Fotoğraf Yükleme"
  >
    <pre-loading v-if="uploading" />
    <template v-else>
      <b-form-group
        label="Dosyaları Seçin"
        label-for="files"
      >
        <b-form-file
          id="files"
          v-model="files"
          placeholder="Yüklemek istediğiniz fotoğrafları seçin..."
          drop-placeholder="Dosyaları buraya bırakın..."
          browse-text="Dosyaları Seçin"
          multiple
        />
      </b-form-group>
      <div class="text-center mb-2">
        <b-button
          variant="primary"
          :disabled="!files.length"
          @click="uploadFiles"
        >
          <FeatherIcon icon="UploadIcon" /> Yüklemeyi Başlat
        </b-button>
      </div>
      <b-alert
        show
        variant="warning"
      >
        <div class="alert-body text-center">
          <p>Önerilen Fotoğrafı Oranı: 900x1200px [Ratio: 3:4]</p>
          <p class="font-small-2 text-danger">
            Önerilen oran dışında yüklenen fotoğraflar uygulamada hatalara sebep olabilir.
          </p>
          <p class="font-small-2 text-danger">
            Fotoğraflarınızın oranından emin değilseniz kırpma aracını kullanın.
          </p>
        </div>
      </b-alert>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormFile, BAlert, BButton,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'MultipleUpload',
  components: {
    BModal,
    BFormGroup,
    BFormFile,
    BAlert,
    BButton,
    PreLoading,
  },
  data() {
    return {
      files: [],
    }
  },
  computed: {
    uploading() {
      return this.$store.getters['multipleUploads/uploading']
    },
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    uploadModal() {
      return this.$store.getters['products/uploadModal']
    },
  },
  methods: {
    uploadFiles() {
      this.$store.dispatch('multipleUploads/uploadImages', {
        folder: 'products',
        upload_images: this.files,
      })
        .then(response => {
          if (response.status) {
            const setResult = this.setImages(response)
            if (setResult) {
              this.uploadModal.status = false
            }
          }
        })
    },
    async setImages(data) {
      data.images.forEach((image, key) => {
        this.dataItem.images.push({
          id: null,
          title: null,
          filename: image,
          thumb: data.thumbs[key],
        })
      })
      return true
    },
  },
}
</script>
